import React, { Fragment } from 'react';
import Form from './Form';
import Container from "common/components/UI/Container";
import SectionHeader from "common/components/SectionHeading";
import { graphql, useStaticQuery } from "gatsby";

import SectionWrapper from "./customer.style";

const CustomerIntake = ({referrer}) => {

  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        CUSTOMER_INTAKE {
          title
          subtitle
        }
      }
    }
  `);
  
  const { title, subtitle } = data.ertCreditJson.CUSTOMER_INTAKE;

  return (
    <SectionWrapper>
      <Container width="950px">
        <SectionHeader title={title} subtitle={subtitle} color="#69A2B1 " />
        <Form referrer={referrer}/>
      </Container>
    </SectionWrapper>
  );
};

export default CustomerIntake;