import * as React from "react";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import NewCustomer from "containers/NewCustomer";
import PartnerWrapper from "../partner.style";
import LaineMiller from "common/assets/image/partners/iwa/laine-miller.jpg";
import IWALogo from "common/assets/image/partners/iwa/iwa.png";

const CustomerIntakePage = ({ location }) => {
    return (
        <Layout location={location.pathname}>
            <Seo title="Integrity Wealth Advisors: ERC New Business Intake Form" />
            <PartnerWrapper>
                <div className="Partner-info">
                    <div className="contact-info">
                        <h3>Laine Miller, CFP</h3>
                        <p className="license">CA Lic #0L07187<br/>
                        (805) 339-0760<br/>
                        laine@iwaplan.com<br/>
                        </p> 
                        <a href="https://iwaplan.com" target="iwa"><img src={IWALogo} /></a>
                    </div>
                    <div className="profile-image">
                        <img src={LaineMiller} />
                    </div>
                </div>
            </PartnerWrapper>
            <NewCustomer referrer={"J46ZlNCdUKAXb0"}/>
        </Layout>
    );
};

export default CustomerIntakePage;