/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import queryString from 'query-string';

const Form = ({ referrer }) => {
  const isBrowser = typeof window !== "undefined"
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  var ref = isBrowser ? localStorage.getItem("ref") : null;

  if (!ref && referrer) {
    ref = referrer;
  }
  React.useEffect(() => {
    const script = document.createElement("script");
    const parsed = queryString.parse(window.location.search);
    //console.log(parsed)
    if (ref) {
      script.src =
        `https://api.miniextensions.com/v1/iframe-embed/et5oJiEzSjJqr15MFS8p.js?absoluteShareUrl=https%3A%2F%2Fapp.miniextensions.com%2Fform%2Fet5oJiEzSjJqr15MFS8p?${"prefill_Referrer=" + ref}`; 
    }
    else {
      script.src = parsed.rec ?
        (console.log("first part"), `https://api.miniextensions.com/v1/iframe-embed/et5oJiEzSjJqr15MFS8p.js?absoluteShareUrl=https%3A%2F%2Fapp.miniextensions.com%2Fform%2Fet5oJiEzSjJqr15MFS8p%2F${parsed.rec}`)
        :
        (console.log("second part"),`https://api.miniextensions.com/v1/iframe-embed/et5oJiEzSjJqr15MFS8p.js?absoluteShareUrl=https%3A%2F%2Fapp.miniextensions.com%2Fform%2Fet5oJiEzSjJqr15MFS8p${window.location.search}`);
    }


    document.body.appendChild(script);
  }, []);

  return (
    <Box>
      <Box
        maxWidth={600}
        margin={'0 auto'}
        sx={{
          '& .MuiOutlinedInput-root.MuiInputBase-multiline': {
            padding: 0,
          },
          '& .MuiOutlinedInput-input': {
            background: theme.palette.background.paper,
            padding: 2,
          },
        }}
      >
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12}>
            <div id="miniextensions-iframe-embed-et5oJiEzSjJqr15MFS8p"></div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Form;
