import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const SectionHeader = styled.header`
  width: 100%;
  margin: 0 auto 80px;
  text-align: center;
  @media only screen and (max-width: 990px) {
    margin-bottom: 55px;
  }
  @media only screen and (max-width: 568px) {
    margin-bottom: 38px;
  }
  h5 {
    color: ${(props) => props.color};
  }

  .text {
    text-align: center;
    font-size: ${themeGet("fontSizes.5")}px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 0px;
    max-width: 470px;
    margin: 20px auto 0;
    color: ${themeGet("colors.text")};
    @media screen and (max-width: 990px) {
      font-size: ${themeGet("fontSizes.2")}px;
      line-height: 20px;
    }
    @media screen and (max-width: 568px) {
      font-size: ${themeGet("fontSizes.2")}px;
      line-height: 20px;
      text-align: center;
      margin-top: 10px;
    }
  }
`;
