import { themeGet } from '@styled-system/theme-get'
import bgPattern from 'common/assets/image/results/results-bg.svg'
import styled from 'styled-components'

const PartnerWrapper = styled.section`
  overflow: hidden;
  margin-top: 100px;
  .Partner-info {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 568px) {
        flex-direction: column;
      }
  }

  .Partner-info-no-photo {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #efefef;
    width: 350px;
    border-radius: 5px;
    @media only screen and (max-width: 568px) {
      flex-direction: column;
    }
}
  .license {
      margin-bottom: 10px;
  }

  .profile-image img {
    max-width: 250px;
    border-radius: 25px;
    border: 1px solid #efefef;
  }

  .Partner-info .contact-info img {
    width: 200px;
  }

  .contact-info {
      max-width: 250px;
      height: 200px;
      border-right: 2px solid #efefef;
      margin-right: 15px;
      margin-top: auto;
      margin-bottom: auto;
      padding-right: 10px;
      @media only screen and (max-width: 568px) {
        width: 80%;
        border: none;
      }
  }

  .Partner-info-no-photo .contact-info {
    height: 200px;
    margin-top: auto;
    margin-bottom: auto;
    border: none;
    @media only screen and (max-width: 568px) {
      width: 80%;
      border: none;
    }
  }

  .profile-image {
    height: 200px;
    width: 200px;
    position: relative;
    border-radius: 50%;
  }

  .profile-image img {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
 
  @media only screen and (max-width: 990px) {
    margin-top: 100px;
  }

  @media only screen and (max-width: 568px) {
    padding-bottom: 0px;
    margin-top: 70px;
    width: 400px

  }
`

export default PartnerWrapper;