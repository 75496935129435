import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const SectionWrapper = styled.section`
  padding-top: 135px;
  overflow: hidden;
  @media only screen and (max-width: 990px) {
    padding-top: 90px;
  }
  @media only screen and (max-width: 568px) {
    padding-top: 78px;
  }
`;

export const PartnershipWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 568px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .feature__block {
    cursor: pointer;
    background: linear-gradient(131.83deg, #ffffff 0%, #e9f3f5 99.21%);
    backdrop-filter: blur(100px);
    align-items: flex-start;
    flex-direction: column;
    padding: 35px;
    transition: all 1s ease;
    &:hover {
      background: linear-gradient(131.83deg, #76adbb 0%, #69a2b1 99.21%);
    }
    &:hover p {
      color: white !important;
    }
    &:hover h3 {
      color: white !important;
    }
    @media only screen and (max-width: 990px) {
      padding: 24px;
    }
    @media only screen and (max-width: 568px) {
      padding: 32px;
    }
    .icon__wrapper {
      position: relative;
      img {
        height: 60px;
        box-shadow: 20px 25px 70px rgba(94, 131, 140, 0.6);
        margin-bottom: 40px;
      }

      @media only screen and (max-width: 990px) {
        img {
          height: 40px;
          margin-bottom: 28px;
        }
      }
      @media only screen and (max-width: 568px) {
        img {
          height: 60px;
          margin-bottom: 30px;
        }
      }
      i {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        color: var(--color);
        transition: all 0.5s cubic-bezier(0.75, -0.5, 0, 1.75);
        &.plus {
          top: 0;
          left: 0;
          transform: translate(20px, 20px) scale(0.1) rotate(-180deg);
        }
        &.circle {
          top: 0;
          right: 0;
          transform: translate(-20px, 20px) scale(0.1) rotate(-180deg);
        }
        &.star {
          bottom: -5px;
          left: calc(50% - 8px);
          transform: translate(0, -20px) scale(0.1) rotate(-180deg);
        }
      }
    }
    .content__wrapper {
      padding-left: 0;
      margin-top: -5px;
      transition: all 0.3s ease;
      @media only screen and (max-width: 1366px) {
      }
      @media only screen and (max-width: 767px) {
      }
      h3 {
        font-size: ${themeGet("fontSizes.10")}px;
        margin-bottom: 16px;
        transition: all 0.2s ease;
        line-height: 26px;
        @media only screen and (max-width: 990px) {
          margin-bottom: 5px;
          font-size: ${themeGet("fontSizes.5")}px;
        }
        @media only screen and (max-width: 568px) {
          margin-bottom: 11px;
          font-size: ${themeGet("fontSizes.6")}px;
        }
      }
      p {
        font-size: ${themeGet("fontSizes.5")}px;
        transition: all 0.2s ease;
        @media only screen and (max-width: 990px) {
          font-size: ${themeGet("fontSizes.2")}px;
        }
        @media only screen and (max-width: 568px) {
          font-size: ${themeGet("fontSizes.2")}px;
        }
      }
    }
    &:hover {
      .icon__wrapper {
        cursor: pointer;
        i {
          opacity: 1;
          visibility: visible;
          &.plus {
            transform: translate(-4px, -4px) scale(1) rotate(180deg);
          }
          &.circle {
            transform: translate(4px, -4px) scale(1) rotate(180deg);
          }
          &.star {
            transform: translate(0, -4px) scale(1) rotate(180deg);
          }
        }
      }
    }
  }
`;

export default SectionWrapper;
