import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import React from "react";
import Text from "common/components/Text";

import { SectionHeader } from "./sectionHeading.style";

const SectionHeading = ({className, title, subtitle, color, text }) => {
  return (
    <Container noGutter>
      <SectionHeader className={className} color={color}>
        <Heading as="h5" content={subtitle} />
        <Heading as="h2" content={title} />
        {text && <Text content={text} className="text" />}
      </SectionHeader>
    </Container>
  );
};

export default SectionHeading;
